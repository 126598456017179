import { FiveStars } from 'components/FiveStars'
import { ComponentType } from 'react'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { GoX } from 'react-icons/go'
import { Button, Modal } from 'reactstrap'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

const Control = styled.button`
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 65px;
  height: 130px;
  background: #cde7f0;
  border: 0;
  outline: none;
  display: none;

  ${up('sm')} {
    display: block;
  }
`

const Prev = styled(Control)`
  left: 0;
  padding: 0 20px 0 0;
  border-radius: 0 100px 100px 0;
`

const Next = styled(Control)`
  right: 0;
  padding: 0 0 0 20px;
  border-radius: 100px 0 0 100px;
`

interface Props {
  testimonial: any
  open: boolean
  onPrev?: () => void
  onNext?: () => void
  onToggle: () => void
}

export const TestimonialModal: ComponentType<Props> = ({ testimonial, open, onPrev, onNext, onToggle }) => (
  <Modal
    size="lg"
    contentClassName="testimonial"
    backdropClassName="testimonial"
    isOpen={open}
    toggle={onToggle}
    fade={false}
    centered
  >
    {onPrev && (
      <Prev type="button" onClick={onPrev}>
        <BsChevronLeft size="34" color="#78ABBD" />
      </Prev>
    )}
    {onNext && (
      <Next type="button" onClick={onNext}>
        <BsChevronRight size="34" color="#78ABBD" />
      </Next>
    )}
    <Button className="testimonial-close inversed-link-gray-chateau" color="link" type="button" onClick={onToggle}>
      <GoX />
    </Button>
    <div className="py-55 px-65">
      <div className="mb-20">
        <FiveStars />
      </div>
      <div className="pt-25 px-50">
        <p className="mb-0 text-primary font-family-serif font-italic font-size-19 line-height-md">
          {testimonial.title}
        </p>
      </div>
      <div className="pt-30 line-height-md" dangerouslySetInnerHTML={{ __html: testimonial.html }} />
      <div className="pt-55">
        <h6 className="text-regal-blue font-weight-bold">{testimonial.quoted_by}</h6>
        <h6 className="text-regal-blue">{testimonial.quoted_at}</h6>
      </div>
    </div>
  </Modal>
)
