import { ComponentType } from 'react'
import { GoStar } from 'react-icons/go'
import { up } from 'styled-breakpoints'
import styled from 'styled-components'

const Wrapper = styled.div`
  text-align: center;
`

const Star = styled(GoStar)`
  margin: 0 4px;
  fill: #fece79;
  width: 14px;
  height: 14px;

  ${up('sm')} {
    width: 16px;
    height: 16px;
  }
`

export const FiveStars: ComponentType = () => (
  <Wrapper>
    <Star />
    <Star />
    <Star />
    <Star />
    <Star />
  </Wrapper>
)
