import { FiveStars } from 'components/FiveStars'
import { TestimonialModal } from 'components/TestimonialModal'
import { ComponentType, useState } from 'react'
import { Button } from 'reactstrap'
import { only, up } from 'styled-breakpoints'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 32px;
  border-radius: 5px;
  position: relative;
  transition: box-shadow 200ms ease-in-out;
  background: ${({ theme }) => theme.colors.foam};

  :hover {
    box-shadow: 0 4px 8px -4px #b8d7e2;
  }

  ${up('sm')} {
    padding: 50px;
  }
`

const Stars = styled.div`
  margin-bottom: 17px;

  ${up('sm')} {
    margin-bottom: 20px;
  }
`

const Title = styled.h5`
  margin-bottom: 25px;
  color: #0078c9;
  font-style: italic;
  font-size: 17px;
  line-height: 29px;

  ${up('sm')} {
    font-size: 19px;
    line-height: 31px;
  }
`

const Content = styled.div`
  margin-bottom: 25px;
  line-height: 1.7;
`

const Author = styled.div`
  margin-top: auto;
`

const QuotedBy = styled.h6`
  margin-bottom: 8px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.regalBlue};
`

const QuotedAt = styled.h6`
  margin: 0;
  color: ${({ theme }) => theme.colors.regalBlue};
`

const ReadMore = styled(Button)`
  margin-top: 25px;
  color: #757d88;

  ${only('xs')} {
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
`

interface Props {
  testimonial: any
  expanded?: boolean
  onClick?: () => void
}

export const TestimonialCard: ComponentType<Props> = ({ testimonial, expanded = false, onClick }) => {
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open)

  return (
    <>
      <Wrapper>
        <Stars>
          <FiveStars />
        </Stars>
        <Title>{testimonial.title}</Title>
        {expanded && <Content dangerouslySetInnerHTML={{ __html: testimonial.html }} />}
        <Author>
          <QuotedBy>{testimonial.quoted_by}</QuotedBy>
          <QuotedAt>{testimonial.quoted_at}</QuotedAt>
        </Author>
        <ReadMore color="link" className="stretched-link" onClick={onClick ?? toggle} block>
          Leer completo
        </ReadMore>
      </Wrapper>
      <TestimonialModal testimonial={testimonial} open={open} onToggle={toggle} />
    </>
  )
}
